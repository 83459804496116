// src/data/blogData.js

import Blog_1 from "../components/Blogs/Blog_1";
import Blog_2 from "../components/Blogs/Blog_2";

let blogItems = [
  {
    id: "1",
    slug: "free-slot-games-for-android-phones",
    image: "/assets/blogs/blog-one.webp",

    metaTitle: "How to Choose the Best Free Slot Game for Android Phone Users?",
    title: "How to Choose the Best Free Slot Game for Android Phone Users?",
    content: "Content of blog 1",
    metaDescription:
      "Here are some things to look for before signing up for the slot games for Android! From top-rated apps, enjoying bonuses, to experiencing fun gaming.",
    canonical:
      "https://milkyway777slots.com/blog/free-slot-games-for-android-phones",

    ogTitle: "How to Choose the Best Free Slot Game for Android Phone Users?",
    ogDescription:
      "Here are some things to look for before signing up for the slot games for Android! From top-rated apps, enjoying bonuses, to experiencing fun gaming.",
    ogImage: "https://milkyway777slots.com/assets/blogs/blog-one.webp",
    component: Blog_1,
    sharingUrl:
      "https://milkyway777slots.com/blog/free-slot-games-for-android-phones",
    date: "2024-11-25",
  },
  {
    id: "2",
    slug: "online-casino-gaming",
    image: "/assets/blogs/blog-two.png",

    metaTitle: "How Will AI Change the Landscape of Online Casino Gaming?",
    title: "How Will AI Change the Landscape of Online Casino Gaming?",
    content: "Content of blog 2",
    metaDescription:
      "AI can suggest downloading casinos online and popular casino games that align with your interests, introducing you to new titles you might enjoy. Read more!",
    canonical: "https://milkyway777slots.com/blog/online-casino-gaming",

    ogTitle: "How Will AI Change the Landscape of Online Casino Gaming?",
    ogDescription:
      "AI can suggest downloading casinos online and popular casino games that align with your interests, introducing you to new titles you might enjoy. Read more!",
    ogImage: "https://milkyway777slots.com/assets/blogs/blog-two.png",
    component: Blog_2,
    sharingUrl: "https://milkyway777slots.com/blog/online-casino-gaming",
    date: "2025-02-14",
  },
  // Add more blogs as needed
];

let blogData = blogItems.sort((a, b) => new Date(b.date) - new Date(a.date));
export default blogData;
